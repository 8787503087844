<template>
  <div
    class="relative flex items-center justify-center rounded-full border dark:border-gray-800"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      borderWidth: `${strokeWidth}px`,
    }"
  >
    <svg
      class="absolute"
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="`0 0 ${size} ${size}`"
      :width="size"
      :height="size"
    >
      <defs>
        <mask :id="`mask-${id}`">
          <circle
            :cx="size / 2"
            :cy="size / 2"
            :r="radius"
            stroke="white"
            :stroke-width="strokeWidth"
            :stroke-dasharray="strokeDasharray"
            :stroke-dashoffset="strokeDashoffset"
            class="origin-center -rotate-90 transition-all duration-500"
          />
        </mask>
      </defs>
      <foreignObject
        x="0"
        y="0"
        :width="size"
        :height="size"
        :mask="`url(#mask-${id})`"
      >
        <div class="bg-gradient h-full w-full"></div>
      </foreignObject>
    </svg>
    <div class="relative">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const {
  size = 48,
  percent = 0,
  strokeWidth = 8,
} = defineProps<{
  size?: number
  percent?: number
  strokeWidth?: number
}>()

const id = useId()

const radius = computed(() => size / 2 - strokeWidth / 2)
const strokeDasharray = computed(() => 2 * Math.PI * radius.value)
const strokeDashoffset = computed(
  () => strokeDasharray.value * (1 - percent / 100),
)
</script>

<style scoped>
.bg-gradient {
  background: conic-gradient(
    from 0 at 50% 50%,
    #ff4d15 0,
    #ffc531 117deg,
    #fff85a 252deg,
    #40ad15 360deg
  );
}
</style>
